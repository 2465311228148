
.navigation {
    position: relative;
    z-index:100;


button {
    flex: 0 0 calc(80% - 1rem);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.3rem;
    padding: 0.5rem auto;
    font-size: 1rem;
    background: rgba(255, 255, 255, 0.089);

  &:hover {
    transform: scale(1.05);
    background: rgba(255, 255, 255, 0.089);
    color:#FFFFFF;
  }
}

  }