* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.crypto-project-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 10;
    height:92vh;
    overflow-y: auto;
    margin-bottom: 3rem;

  .project-header {
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 3rem;
  }
  
  .project-title {
    margin-top: 4rem;
    margin: 0.8rem;
    font-size: 2.5rem;
    font-weight: 600;
    color: white;
    display: flex;
    flex-direction: column;
  }
  
  .project-description {
    font-size: 1rem;
    font-family:monospace;
    line-height: 2rem;
    max-width: 66%;
    text-align: justify;
    text-align: center;
    color:#FFFFFF;
    margin-bottom: 2rem;
  }

  .button-container {
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 4rem;
    max-width: 96%;
  }

  .boxy {
    margin: 3rem;
    margin-top: 2rem;
    padding: 1.5rem;
    max-width: 96%;
    text-align: center;
    text-wrap: wrap;
    z-index: 10;
    background:  rgba(29, 81, 139, 0.082);
    backdrop-filter: blur(1.5rem);
    border-radius: 40px;
    z-index: 1;
  }

    .techStack {
      color: rgb(216, 214, 228);
      font-size: 1rem;
      margin-top: 0.6rem;
      font-weight: 600;
    }

    .about-especs {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 0.7rem;
      font-family:monospace;
      font-weight: 200;
      line-height: 1rem;
      position: relative;
      color: rgb(12, 10, 20);
    }
    
    .transparent-box {
      width: 90%;
      max-width: 1024px;
      background-color: rgba(54, 34, 34, 0.192);
      border-radius: 10px;
      padding: 1.5rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    
    .video-container {
      position: relative;
      width: 100%;
      max-width: 1000px;
      height: 500px;
      padding-bottom: 40%;
      margin-top: 1rem;
    }
    
    .react-player {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      right: 0;
      margin-bottom: 1rem;
    }

  @media (max-width: 768px) {

    .crypto-project-page {
      padding: 1rem;
      margin-bottom: 2rem;
      max-width: 100%;
    }

    .project-description {
      font-size: 0.8rem;
      line-height: 1.8rem;
      max-width: 96%;
    }

    .boxy {
      margin: 0.6rem; /* Reduce margin for smaller screens */
      padding: 0.3rem; /* Reduce padding for smaller screens */
    }
  
    .techStack {
      font-size: 0.875rem; /* Adjust font size for smaller screens */
      margin-top: 0.3rem; /* Adjust margin for smaller screens */
    }

    .react-player-wrapper {
      padding-top: 56.25%; /* Maintain aspect ratio */
      margin-top: 1rem;
      width: 96%;
    }

    .react-player {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      margin-bottom: 1rem;
    }
    
  }
}