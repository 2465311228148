* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.blog-page {
  margin-bottom: 2rem;
  margin-top: 1rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction:column;
  text-align: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  overflow:auto;
  z-index:10;

  .post-title {
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    text-decoration: none !important;
  }
  
  .blog-title {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 1rem;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-size: 2.8rem;
    font-weight: 600;
    color: #ffffff;
  }

  .boxy {
    margin: 1rem;
    margin-top: 2rem;
    padding: 1.6rem;
    padding-top: 3rem;
    max-width: 70%;
    text-align: center;
    text-wrap: wrap;
    z-index: 10;
    background:  rgba(29, 81, 139, 0.082);
    backdrop-filter: blur(1rem);
    border-radius: 40px;
    z-index: 1;
  }

  .button-container {
    display: flex;
    justify-content:center;
    align-items: center;
    flex-wrap: wrap;
    z-index: 100;
  }

  .boxie {
    margin-top: 5rem;
    margin-bottom: 3rem;
    max-width: 95%;
    text-align: center;
    z-index: 1000;
  }

  .lets-talk {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5rem;
    z-index: 10;
    max-width: 100%;
  }

  .paper-link {
    display:flex;
    justify-content: center;
    align-self: center;
    color: rgb(211, 247, 8);
    text-decoration: none !important;
    font-weight: 700;
  }

  .blog-post {
      flex: 0 0 calc(75% - 1rem);
      flex-wrap: wrap;
      justify-content: center;
      position:relative;
      max-width: 80%;
      margin: 1rem auto;
      padding: 1rem;
      border-radius: 8px;
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      text-decoration: none !important;
      backdrop-filter: blur(1rem);
      transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
        
  .blog-post:hover {
    background:  rgba(28, 180, 8, 0.37);
    backdrop-filter: blur(5px);
    border-radius: 1.5rem;
    transform: scale(1.03);
    text-decoration: none !important;
  }  
}
  .blog-overlay {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

  &:hover .blog-overlay {
    opacity: 1;
    transform: translateY(0);
  }
}
  
h1 {
  color: #333;
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.about-text {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 2rem;
  font-size: 1rem;
  font-family:monospace;
  font-weight: 200;
  line-height: 2rem;
  position: relative;
  color: #fafafa;
}

.blog-container {
  display: flex;
  flex-wrap: wrap;
  justify-content:flex-start;
  position:relative;
  background-color: var(--primary-color);
  color: #fff;
  padding: 1.5rem;
  border-radius: 6px;
  transition: background-color 0.3s ease;
  z-index: 10;

.a {
  color: #f0f0f0;
  text-decoration: none !important;
  margin-top: 1rem;
}

ul {
  list-style-type: none;
}
}

.recent-post {
  display: flex;
  justify-content: center;
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-top:4rem;
  color:rgb(45, 46, 51);
  text-decoration: none;
}

p {
  text-decoration: none !important;
}

/* Apply responsive styles */
@media (max-width: 768px) {
  .blog-page {
    margin-bottom: 4rem;
    flex: 0 0 calc(90% - 20px);
  }

  .about-text {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-size: 0.8rem;
    font-weight: 200;
    line-height: 1.8rem;
    position: relative;
    color: #fafafa;
  }

  .box {
    padding: 1rem;
    margin-top: 1.5rem;
    }

    .boxy {
      margin-top: 1rem;
      padding: 1.6rem;
      max-width: 94%;
      text-align: center;
      z-index: 100;
    }

    .lets-talk {
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 100;
    }

    .button-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-bottom: 4rem;
    }
  }
}