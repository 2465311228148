* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y:auto !important;
  }


.contact-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 3rem;
  height: 92vh;
  width: 100%;

h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
}

.contact {
  font-size: 2rem;
  font-weight: 600;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
}

form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom:2rem;
}

.boxy {
  margin: 2rem;
  margin-top: 2rem;
  padding: 1.5rem;
  max-width: 90%;
  text-align: center;
  text-wrap: wrap;
  z-index: 10;
  background:  rgba(29, 81, 139, 0.082);
  backdrop-filter: blur(1rem);
  border-radius: 40px;
  z-index: 1;
}

.sub-title {
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 200;
  color: rgb(211, 247, 8);
  text-align: center;
  margin: 1.5rem;
}

input,
textarea {
  font-family: "Roboto", sans-serif;
  margin-bottom: 1rem;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 10px;
  outline: none;
  background-color: transparent;
  color: aliceblue;

}

textarea {
  resize: vertical;
  padding: 1.2rem;
  background-color: transparent;
  color: aliceblue;
}

button[type="submit"] {
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  transition: background-color 0.1s ease;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  word-spacing: 1px;
  border: 2.4px solid #fff;
  border-radius: 3rem;
}

button[type="submit"]:hover {
  transition: 0.5s;
  background-color: #000000fa;
  color: #fff;
}


.error-message {
  color: #ff0000;
  font-size: 14px;
  margin-top: 5px;
}

.social-menu {
  text-align: center;
  margin-top: 20px;
}

.social-link {
  display: inline-block;
  margin: 0 10px; /* Adjust the spacing between icons */
  font-size: 24px; /* Adjust the size of the icons */
  color: aliceblue; /* Adjust the color of the icons */
}
}

/* Responsive design */
@media (max-width: 768px) {
  .social-link {
    font-size: 20px; /* Adjust the size of the icons for smaller screens */
  }
}
