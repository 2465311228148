@import '~@fortawesome/fontawesome-free/css/all.css';

  .blog-page {
    scroll-behavior: smooth;
    overflow:scroll;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    position: relative;
    z-index: 10;
    height: 100vh;


  .blog-head {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
    z-index: 20;

  }

  .blog-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:space-evenly;
    margin: 0.7rem;
    color:  rgb(211, 247, 8);
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 20;
 
  }
  .more-posts-section {
    margin-top: 2rem;
    margin-bottom: 6rem;
    width: 70%;
    padding: 2rem;
    border-radius: 40px;
    z-index: 1;
    background:  rgba(29, 81, 139, 0.082);
    backdrop-filter: blur(5px);
    border: 2px solid rgba(29, 80, 139, 0.103);
  }
  
  .blog-content {
    color: #fafafa;
    font-size: 1.5rem;
    display: flex;
    z-index: 20;
    max-width: 66%;
    margin: 1rem auto;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem; 
    padding: 2rem;
    border-radius: 1rem;
    background: #2f7fad54;

}

/* Apply responsive styles */
@media (max-width: 768px) {

  .blog-content {
    color: #fafafa;
    font-weight: 0.8rem;
    display: flex;
    z-index: 20;
    max-width: 90%;
    margin: 1rem auto;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 2rem; 
    padding-bottom: 5rem;
    padding: 1.5rem;
}

}

}
