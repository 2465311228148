*,*:before, *after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* src/App.css or src/index.css */

/* For smaller screens, adjust the layout */
@media screen and (max-width: 768px) {
  .header {
    display: flex;
    justify-content: center;
  }
  
  .header a {
    margin: 5px;
  }
  

}
