* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.moms-project-page {
    overflow-y: auto;
    z-index:10;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 3rem;
    padding: 1rem;
    height: 92vh;


  .project-header {
    margin-top: 3rem;
    margin-bottom: 3rem;
    }
  
  .project-title {
    margin: 0.8rem;
    margin-top: 4rem;
    font-size: 2.5rem;
    font-weight: 600;
    color:white;
    display: flex;
    flex-direction: column;
    text-align: center;
    }
  
  .project-description {
    font-size: 1rem;
    font-family:monospace;
    line-height: 2rem;
    max-width: 66%;
    text-align: center;
    color:#FFFFFF;
    margin: 0 auto;
    margin-bottom: 2rem;
    }
  
    button {
      padding: 10px 20px;
      margin-top: 1rem;
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      transition: background-color 0.1s ease;
      text-decoration: none;
      font-weight: bold;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      color: aliceblue;
      word-spacing: 1px;
      background: transparent;
      border: 2.4px solid #fff;
      border-radius: 3rem;
      margin-bottom: 3rem;
    }
    
    button:hover {
      transition: 0.5s;
      color: #fff;
      background: #000;
    }

    .boxy {
      margin: 3rem;
      margin-top: 2rem;
      padding: 1.5rem;
      max-width: 96%;
      text-align: center;
      text-wrap: wrap;
      z-index: 10;
      background:  rgba(29, 81, 139, 0.082);
      backdrop-filter: blur(2.5rem);
      border-radius: 40px;
      z-index: 1;
    }

    
    .about-especs {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 0.7rem;
      font-family:monospace;
      font-weight: 200;
      line-height: 1rem;
      position: relative;
      color: black;
    }

  .transparent-box {
    width: 90%;
    max-width: 1024px;
    background-color: rgba(31, 27, 27, 0.199);
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 1rem;
    }
  
  .figma-container {
    position: relative;
    max-width: 1024px;
    width: 100%;
    height: 100%;
    overflow: hidden;
    margin-top: 1rem;
    margin-bottom: 20rem;
    padding-bottom: 10%;
    padding-top: 3%;
  }

  .figma-title {
    font-size: 1.5rem;
    font-weight: bold;
    color:white;
    margin: 1rem;
    margin-bottom: 1rem;
  }

@media (max-width: 768px) {

  .project-description {
    padding-bottom: 10%;
    font-size: 0.8rem;
    line-height: 1.8rem;
    max-width: 100%;
  }
}

}