* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    figma-container {
        position: relative;
        width: 100%;
        padding-bottom: 30%;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
        }
    }
}