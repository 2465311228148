* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* src/App.css or src/index.css */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  h1 {
    color: rgba(240, 248, 255, 0.767);
  }
}

#background_container {
  top: 0px;
  bottom: 0px;
  position: fixed;
  width: 100%;
  height: 100%;
}

.loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-background {
  background-color: black;
}