* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h1 {
color: black;
font-size: 1.5rem;
}

h4 {
  font-size: 1rem;
  font-weight: 200;
  color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;

}

i {
  color:  rgb(211, 247, 8);
}

p {
  padding-top: 0.8rem;
}

  .main-container {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    z-index: 100;
    height: 90vh;
    overflow-y: auto;
  }

  .description {
    line-height: 2.3rem;
    font-size: 1.3rem;
    font-weight: 500;
    margin: 0.5rem;
    color: #FFF;
  }
  
  .project-title {
    margin: 0.8rem;
    margin-top: 4rem;
    font-size: 2.5rem;
    font-weight: 600;
    color: white;
    display: flex;
    flex-direction: column;
  } 

  h2 {
    color: rgb(255, 255, 255);
    font-size: 1.6rem;
    margin-top: 1.5rem;
    font-weight: 700;
    }

.subTitle {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 2rem;
  font-weight: 600;
  color: #ffffff;
}

  .desc-experience {
    text-align: justify;
    line-height: 2.5rem;
    font-size: 1.7rem;
    float: right;
    font-weight: 500;
    max-width: 100%;
    margin: 0.5rem;
    text-align: center;
    color: rgb(245, 245, 245);
  }
    
  .upper-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 11;
    margin-top: 2.5rem;
    margin-bottom: 2rem;
  }
  
  .button {
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    transition: background-color 0.1s ease;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    color: aliceblue;
    word-spacing: 2px;
    background: transparent;
    border: 2.4px solid #fff;
    border-radius: 3rem;
  }
  
  .button:hover {
    transition: 0.5s;
    color: #fff;
    background: #000;
  }

  .button-container {
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
    z-index: 100;
  }

  .boxy {
    margin: 3rem;
    margin-top: 2rem;
    padding: 1.5rem;
    max-width: 80%;
    text-align: center;
    text-wrap: wrap;
    z-index: 10;
    background:  rgba(29, 81, 139, 0.082);
    backdrop-filter: blur(1rem);
    border-radius: 40px;
    z-index: 1;
  }
  
  .boxie {
    margin-top: 10rem;
    padding: 2rem;
    max-width: 80%;
    text-align: center;
    z-index: 100;
  }

.timeline-container-wrapper {
    height: 100vh;
    margin-bottom: 2rem;
    position:relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
   
    .timeline-container {
      margin: 2rem;
      margin-bottom: 10rem;
      padding-bottom: 2rem;
      transition: transform 0.2s ease-in-out;

      &:hover {
        transform: scale(1.02);
      } 
    }
}

.vertical-timeline {
  display:unset;
  position: relative;
  max-width: 80%;
  margin: 4rem 0;

} 

.vertical-timeline-element-title {
  color: white !important;
}
  .project-overlay {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

    h3,
    p {
      color: rgb(255, 255, 255);
      text-align: justify;
      line-height: 2.3rem;
      font-size: 1rem;
      padding: 0 1rem;
      max-width: 80%;
    }
  
  .project-link {
    color: rgb(211, 247, 8);
    text-decoration: none !important;
    margin-top: 1rem;
  }

  .projects-title {
    font-size: 1.8rem;
    color: White;
    margin-top:4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  &:hover .project-overlay {
    opacity: 1;
    transform: translateY(0);
  }
}
  
  @media (max-width: 768px) {
    .description {
      font-size: 0.8rem;
      line-height: 1.8rem;
      font-size: 500;
      width: 90%;
      color: white;
    }

    .boxie {
      margin-top: 1rem;
      max-width: 80%;
      text-align: center;
      z-index: 100;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .project-description {
      font-size: 1.2rem;
      line-height: 1.8rem;
      width: 90%;
    }

    .boxie {
      margin-top: 12rem;
      z-index: 10;
      }

      .button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0.3rem;
      }

      .projects-container {
        max-width: 50%;
        gap: 3rem;
        margin: 3rem;
      }
    }
  

  