
.share.icon.collections {
    background-color: semi-transparent !important;

    .div {
        display: flex;
        justify-content:space-between;
        align-items: center;
        background-color: semi-transparent !important;
        fill:#f5f5f5;
    }
     a {
        margin-right: 10px; // Add some space between the buttons
        display: inline-block; // Make the buttons inline
        justify-content: space-between;
        background-color: #f5f5f5; // Add a background color to the buttons
        padding: 10px; // Add some padding to the buttons
        border-radius: 5px; // Round the corners of the buttons
    }
}
