
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.fab, .fa-solid {
  font-size: 1.3rem;
  color: rgb(211, 247, 8);
  margin: 0.4rem;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width:100%;
  padding-right: 25px;
  text-align: center;
  position: sticky;
  top: 0;
  padding: 1rem;
  z-index: 1000; /* To keep the navbar on top of other content */
  background-color:#7066e00a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adds a subtle shadow */


  .navbar-brand {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #fff;
    font-weight: bold;
    font-size: 12px;

    img {
      width: 30px;
      height: 26px;
    }
  }

  .navbar-menu {
    display:flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 1.5rem;
    left: 50%;
    letter-spacing: 1px;
    line-height: 5rem;
    font-size: 20rem;
    font-weight: 800;
    align-items: center;
    transform: translateX(-50%);
    background:  rgba(29, 80, 139, 0.11);
    backdrop-filter: blur(1.5rem);
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.3s ease;

    &.active {
      margin-top: 2rem;
      position:fixed;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transition: opacity 0.9s ease;
      min-height: 100vh;
      width: 100%;
    }

    ul {
      list-style: none;
      padding: 25px 45px;

      li {
        margin-top: 1.8rem;
        margin-bottom: 1rem;
        font-size: 1.3rem;
        text-align: center;
        a {
          color:rgb(211, 247, 8);
          text-decoration: none;
          transition: color 0.2s ease;

          &:hover {
            transition: opacity 0.9s ease;
            color: rgb(36, 36, 36);
            transition: color 0.25s ease;
            font-weight: bold;
          }
        }
      }
    }
  }

  .navbar-burger {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 3rem;
    height: 2rem;
    left: 2rem;
    cursor: pointer;

    span {
      display: block;
      width: 100%;
      height: 0.35rem;
      background-color: #fff;
      transition: transform 0.3s ease;

      &:nth-child(2) {
        transform-origin: center;
      }
    }

    &.active {
      span {
        &:first-child {
          transform: translateY(7px) rotate(45deg);
        }
        &:nth-child(2) {
          opacity: 0;
        }
        &:last-child {
          transform: translateY(-7px) rotate(-45deg);
        }
      }
    }
  }

  @media (max-width: 1023px){
    .navbar-menu {
      display:flex;
      align-items: center;
      justify-content: center;
      background:  rgba(29, 80, 139, 0.062);
      backdrop-filter: blur(2rem);
      letter-spacing: 2px;
      line-height: 2.5rem;
      font-size: 7rem;
      font-weight: 800;
      left: 0%;
      transform: translateX(0);
      z-index: 1000;
      &.active {
        transition: opacity 0.9s ease;
        min-height: 100vh;
        width: 100%;
    }
  }

  li {
    font-size: 1rem;
  }
}
}
