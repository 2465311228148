* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

h2 {
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
  margin-top: 1.5rem;
  font-weight: 700;
  }

.cloudbrewers-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-bottom: 5rem;
    z-index: 10;
    height:92vh;
    overflow-y:auto;
    max-width: 100%;

  
    .project-header {
      margin-top: 3rem;
      margin-bottom: 3rem;
      display:flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 80%;
    }
  
    .project-title {
      margin: 0.8rem;
      margin-top: 4rem;
      font-size: 2.5rem;
      font-weight: 600;
      color: white;
      display: flex;
      flex-direction: column;
    }
  
    .project-description {
      font-size: 1.1rem;
      font-family:monospace;
      line-height: 2rem;
      max-width: 80%;
      text-align: center;
      color:#FFFFFF;
      margin: 0 auto;
    }

    .button-container {
      display: flex;
      justify-content:center;
      flex-wrap: wrap;
      gap: 1rem;
      margin-bottom: 4rem;
      max-width: 96%;
    }

    .cloud-link {
      color: #ffffff;
      text-decoration: none;
      font-size: 1rem;
      color: rgb(211, 247, 8);
      margin: 1rem;
    }

    .boxy {
      margin: 3rem;
      margin-top: 2rem;
      padding: 1.5rem;
      max-width: 96%;
      text-align: center;
      text-wrap: wrap;
      z-index: 10;
      background:  rgba(29, 81, 139, 0.082);
      backdrop-filter: blur(2.5rem);
      border-radius: 40px;
      z-index: 1;
    }

    .about-especs {
      display: flex;
      align-items: center;
      flex-direction: column;
      font-size: 0.7rem;
      font-family:monospace;
      font-weight: 200;
      line-height: 1rem;
      position: relative;
      color: black;
    }

    .gallery {
      display: flex;
      flex-direction: column;
      align-items:center;
      margin:auto auto;
      margin-bottom: 2rem;
      margin-top: 2rem;
    
    img {
      max-width: 800px;
      width: 94%;
    }
  }
/*
    .transparent-box {
      width: 95%;
      max-width: 1024px;
      background-color: rgba(255, 255, 255, 0.144);
      border-radius: 10px;
      padding: 1rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    
    .video-container {
      position: relative;
      width: 100%;
      max-width: auto;
      height: 500px;
      padding-bottom: 30%;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    
    .react-player {
      position: absolute;
      width: 100%;
      height: auto;
      left: 0;
      right: 0;
      margin-bottom: 1rem;
    }
*/
    @media (max-width: 768px) {

      .project-description {
        font-size: 0.8rem;
        line-height: 1.8rem;
        max-width: 100%;
        }
        .project-header {
          margin-top: 1rem;
          margin-bottom: 3rem;
          display:flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 100%;
        }

        .boxy {
          max-width: 100%; /* Ensure it doesn't exceed the viewport width */
        }
      }
}