.blog-animation {
    text-align: center;
    margin-top: 0.8rem;
  }
  
  .blog-text {
    font-size: 0.9rem;
    font-weight: 200;
    font-style: italic;
    position: relative;
    color:rgb(211, 247, 8);
  
    /* Animation styles */
    &::before {
      position: absolute;
      right: -0.3rem;
      animation: blink 0.3s;
    }
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
  }
  