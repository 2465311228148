* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .auto-project-page {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      z-index: 10;
      height:92vh;
      overflow-y: auto;
  
    
      .project-header {
        text-align: center;
        //margin-top: 3rem;
        margin-bottom: 3rem;
      }
    
      .project-title {
        margin: 0.8rem;
        margin-top: 4rem;
        font-size: 2.5rem;
        font-weight: 600;
        color: rgb(255, 255, 255);
        display: flex;
        flex-direction: column;
      }
    
      .cloud-link {
        color: antiquewhite;
        text-decoration: none !important;
        margin-top: 1rem;
      }
  
      .project-description {
        font-size: 1rem;
        font-family:monospace;
        line-height: 2rem;
        max-width: 66%;
        text-align: justify;
        text-align: center;
        color: #ffffff;
        margin: 0 auto;
      }
    
      .photo-gallery {
        display: flex;
        grid-gap: 10px;
        max-width: 95%;
        max-height: auto;;
        //margin: 0 auto;
      }
    
      .project-photo {
        width: 100%;
        height: auto;
        border: 1px solid #ddd;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
      }
    
      .boxy {
        margin: 3rem;
        margin-top: 2rem;
        padding: 1.5rem;
        max-width: 96%;
        text-align: center;
        text-wrap: wrap;
        z-index: 10;
        background:  rgba(29, 81, 139, 0.082);
        backdrop-filter: blur(2.5rem);
        border-radius: 40px;
        z-index: 1;
      }
  
      
      .about-especs {
        display: flex;
        align-items: center;
        flex-direction: column;
        font-size: 0.7rem;
        font-family:monospace;
        font-weight: 200;
        line-height: 1rem;
        position: relative;
        color: black;
      }
      .gallery {
        display: flex;
        flex-direction: column;
        align-items:center;
        margin:auto auto;
        margin-bottom: 2rem;
        margin-top: 2rem;
      
      img {
        max-width: 800px;
        width: 94%;
      }
    }
  .navbar {
      background-color:#080a25; /* Change this to your desired color */
    }
  
        @media (max-width: 768px) {
          .project-description {
            font-size: 0.8rem;
            line-height: 1.8rem;
            max-width: 96%;
          }
        }
  
    }