* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  align-items: center;
  justify-content: center;
  height: 100%;
  overflow-y: auto !important;
}

p {
  font-size: 1.2rem;
  line-height: 1.5rem;
  padding: 0.4rem;
  color: #ffffff;
}

h2 {
  color: rgb(255, 255, 255);
  font-size: 1.6rem;
  margin-top: 2rem;
  font-weight: 700;
}


h2 {
  color: rgb(211, 247, 8);
  font-size: 2.5rem;
}

h4 {
  color: rgb(20, 20, 18);
  font-weight: 600;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 100;
  position: relative;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 10px;
}

.hero-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  font-family: "Roboto", sans-serif;
  transform: translateZ(-5px) scale(1.1);
  object-fit: cover;
  z-index: -1;
}

.hero-container.hidden {
  display: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: -2;
}

.boxy {
  margin: 3rem;
  margin-top: 2rem;
  padding: 1.5rem;
  max-width: 96%;
  text-align: center;
  text-wrap: wrap;
  z-index: 10;
  background:  rgba(29, 81, 139, 0.082);
  backdrop-filter: blur(2.5rem);
  border-radius: 40px;
  z-index: 1;
}

.lets-talk {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 7rem;
  max-width: 100%;
  z-index: 12;
}

.scroll {
  position: relative;
  margin-top: 0.7rem;
  z-index: 100;
  font-size: 0.8rem;

  &:hover {
    transform: scale(1.15);
    color: rgba(240, 248, 255, 0.534);
  }
}

.hi {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 3rem;
  font-weight: 600;
  color: #d3f708;
}

.subTitle {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 2rem;
  font-weight: 600;
  color: #2c2525;
}

.subTitle_work {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 2rem;
  font-weight: 600;
  color: #ffffff;
  padding-bottom: 0.5rem;
}
.about-text {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.2rem;
  font-weight: 200;
  line-height: 2rem;
  position: relative;
  color: #fafafa;
}

.about-text_projects {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1rem;
  font-weight: 200;
  line-height: 1.8rem;
  position: relative;
  color: #fafafa;
  padding-bottom: 2rem;
}

.name {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding-top: 2.5rem;
  margin: 1rem;
}

.button-container {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.project-pic {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 10px;
  margin: 1.2rem auto;
}

.project-card {
  background-color: rgba(240, 248, 255, 0.767);
  border: 1px solid #ccc;
  padding: 1rem;
  margin: 1rem;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}

.project-pic {
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 1.2rem;
  margin-bottom: 1.2rem;

  &:focus {
    outline: none;
    border: #ffffff;
  }
}

.project-title {
  position: relative;
  margin-top: 1rem;
  z-index: 100;
  font-size: 1.6rem;
  font-weight: 600;
  color: #2c2525;

  &:hover {
    transform: scale(1.05);
    color: rgb(211, 247, 8);
  }
}

.projects-title {
  color: black;
  margin-top: 4rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.projects-subTitle {
  font-size: 0.9rem;
  font-family: monospace;
  margin-bottom: 0.3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.projects-sec {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: rgb(240, 248, 255);
  transform: translateZ(-5px) scale(1.1);
  object-fit: cover;
}

.projects-container {
  display: grid;
  align-items: center;
  width: 100%;
  //grid-template-columns: repeat(auto-fill, minmax(250px, 650px));
  position: relative;
  flex: 0 0 calc(90% - 20px);
  gap: 2rem;

  @media (min-width: 650px) {
    grid-template-columns: repeat(2, 1fr);
  }
}

.project-container {
  position: relative;
  overflow: auto;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
}

.content {
  width: 85%;
  &:hover {
    color: #000;
  }
}

.bonk {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 2.3rem;
  font-weight: 500;
  color: #ffffff;
}

button {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 0.9rem;
  font-weight: bold;
  transition: background-color 0.1s ease;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #fff;
  word-spacing: 2px;
  width: 15rem;
  background: transparent;
  border: 2.4px solid #fff;
  border-radius: 3rem;
}

button:hover {
  transition: 0.5s;
  color: #fff;
  background: #000;
}

/* Apply responsive styles */
@media (max-width: 768px) {

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }

  .projects-container {
    width: 80%; /* Projects in a single column */
    flex: 0 0 calc(95% - 20px);
  }

  .box {
    display: none;
  }

  .sub-name {
    padding: 0.5rem;
    color: #fff;
    font-size: 1rem;
    font-size: 100;

    &:hover {
      transform: scale(1.05);
      color: rgb(211, 247, 8);
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0.3rem;
  }

  .button-up {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1rem;
  }

  .name {
    font-size: 1rem;
    font-weight: 400;
    margin-top: 1rem;
    color: rgb(240, 248, 255);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .bonk {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.5rem;
    font-weight: 500;
    color: #ffffff;
  }

  // .lets-talk {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   position: relative;
  //   z-index: 10;
  //   //max-height: fit-content;
  //   //max-height: 92vh;
  // }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .projects-container {
    max-width: 80%; /* Projects in a single column */
    flex: 0 0 calc(98% - 20px);
    gap: 2rem;
    margin-bottom: 1rem;
  }
  .sub-name {
    display: none;
  }

  .name {
    font-size: 1.5rem;
    font-weight: 400;
    margin-top: 1rem;
    color: rgb(211, 247, 8);
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1.5rem;
  }

  .boxie {
    max-width: 90%;
    text-align: center;
    z-index: 1000;
    margin-top: 40rem;
  }

  .lets-talk {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    max-width: 100%;
  }

  .button-up {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 7rem;
  }

}

@media (min-width: 1024px) {
  .projects-container {
    max-width: 65%;
    flex: 0 0 calc(90% - 20px);
    gap: 3rem;
    margin-bottom: 10%;
  }

  .sub-name {
    display: none;
  }
}

/* Customize scrollbar appearance */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(211, 247, 8); /* Color of the scrollbar thumb */
  border-radius: 8px;
}

::-webkit-scrollbar-track {
  background-color: #eeeeee42; /* Color of the scrollbar track */
  border-radius: 10px;
}
